<template>
  <footer class="bg-slate-900 px-3 py-10 lg:py-6 lg:px-4 2xl:px-6">
    <div class="flex flex-col-reverse justify-center items-center md:flex-row md:items-start md:justify-between md:space-x-4">
      <div class="flex items-center justify-center flex-col md:items-start max-md:hidden h-full">
        <NuxtLink
          to="/"
          :replace="route.fullPath.includes('sports')"
          class="inline-block w-24"
        >
          <img
            src="~/assets/img/logos/MetaWin-logo-white.svg"
            alt="MetaWin logo"
            class="inline-block w-full transition-all"
          >
        </NuxtLink>
        <p class="text-slate-400 text-xs text-center md:text-left mt-1">
          Copyright © {{ footerYear }} MetaWin
        </p>
        <p class="text-slate-400 text-xs text-center md:text-left">
          Patent Pending
        </p>
      </div>
      <div
        class="flex items-center flex-col mt-4 md:mt-0 max-md:order-2"
        :class="[showGamingContent ? 'md:w-2/5 md:items-start' : 'md:w-1/4']"
      >
        <div
          class="max-xs:flex-col flex gap-[52px] max-xs:gap-0 mb-6"
        >
          <div class="mt-1 text-center md:text-left mb-4 md:mb-0">
            <p class="text-white text-sm font-semibold">About us</p>
            <ul class="flex flex-col md:items-start">
              <li v-if="isUserLogged">
                <FooterLink to="/faq">FAQ</FooterLink>
              </li>
              <li>
                <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
              </li>
              <li>
                <FooterLink to="/cookie-policy">Cookie Policy</FooterLink>
              </li>
              <li>
                <FooterLink to="/games/terms-and-conditions">
                  Terms and Conditions
                </FooterLink>
              </li>
              <li>
                <FooterLink to="/sports/terms-and-conditions">
                  Sports Terms and Conditions
                </FooterLink>
              </li>
            </ul>
          </div>
          <div
            v-if="showGamingContent"
            class="mt-1 text-center md:text-left mb-4 md:mb-0"
          >
            <p class="text-white text-sm font-semibold">Gaming</p>
            <ul class="flex flex-col md:items-start">
              <li>
                <FooterLink to="/underage-gaming-policy">
                  Underage Gaming Policy
                </FooterLink>
              </li>
              <li>
                <FooterLink to="/self-exclusion-policy">
                  Self-Exclusion Policy
                </FooterLink>
              </li>
              <li>
                <FooterLink to="/responsible-gambling">
                  Responsible Gambling
                </FooterLink>
              </li>
              <li>
                <FooterLink to="/games/kyc">KYC Policy</FooterLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mb-4 md:mb-0 md:w-2/5">
        <p class="text-white max-md:text-center text-lg font-semibold">
          Join our Web3 community
        </p>
        <div class="flex items-center flex-row justify-center md:justify-start space-x-3 py-2">
          <NuxtLink to="https://discord.gg/the-arena" target="_blank">
            <img
              src="~/assets/img/logos/discord.svg"
              alt="Discord logo"
              class="w-6"
            >
          </NuxtLink>
          <NuxtLink to="https://twitter.com/Meta_Winners" target="_blank">
            <img
              src="~/assets/img/logos/twitter.svg"
              alt="Twitter logo"
              class="w-6"
            >
          </NuxtLink>
          <NuxtLink to="https://www.instagram.com/metawin.eth" target="_blank">
            <img
              src="~/assets/img/logos/instagram.svg"
              alt="Instagram logo"
              class="w-6"
            >
          </NuxtLink>
          <NuxtLink to="https://t.me/metawinlfg" target="_blank">
            <img
              src="~/assets/img/logos/telegram.svg"
              alt="Telegram logo"
              class="w-6"
            >
          </NuxtLink>
        </div>
        <div class="flex flex-col items-center md:hidden my-10">
          <NuxtLink
            to="/"
            :replace="route.fullPath.includes('sports')"
            class="inline-block w-24"
          >
            <img
              src="~/assets/img/logos/MetaWin-logo-white.svg"
              alt="MetaWin logo"
              class="inline-block w-full transition-all"
            >
          </NuxtLink>
          <p class="text-slate-400 text-xs text-center md:text-left mt-1">
            Copyright © {{ footerYear }} MetaWin
          </p>
          <p class="text-slate-400 text-xs text-center md:text-left">
            Patent Pending
          </p>
        </div>
        <div class="space-y-1 mt-2">
          <p
            v-if="isCompetition"
            class="text-slate-400 text-xs text-center mb-4 md:text-left"
          >
            18+. T&C apply. <NuxtLink to="/amoe" :replace="route.fullPath.includes('sports')">No purchase necessary</NuxtLink>.<br class="block md:hidden">Void where prohibited by law.
          </p>
          <p
            v-if="showGamingContent"
            class="text-slate-400 text-xs text-center md:text-left mb-3"
          >
            Gaming Activity excluding sweepstakes and blockchain competitions on Metawin.com is licensed and regulated by the Government of Curaçao under license number OGL/2024/1695/0935 issued by the Curacao Gaming Control Board to Asobi&nbsp;N.V. (company number 153109)
          </p>
        </div>
        <div
          v-if="showGamingContent"
          class="flex items-center justify-end gap-0 max-md:justify-center order-1 mb-10"
        >
          <img
            src="~/assets/img/logos/18+_icon.png"
            alt="+18 logo"
            class="inline-block w-6 mt-4 transition-all"
          >
          <div class="mt-4 lg:mb-0 lg:order-4 lg:mt-3 ml-2">
            <NuxtLink
              to="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJa0V3TTJOdlJUWmFObmx1TVZGWU9FOHhhVWRtVUdjOVBTSXNJblpoYkhWbElqb2lWRVUwYURsQlVFbERNRkZXYlZoMWFIVkNVazF2ZHowOUlpd2liV0ZqSWpvaU9XVmlOREkxTnpRNU1UVTVOV1JrWWpZeE9Ea3pOV0ptTWpsbFptRTRPRFl5TmpBd09URmhNVEV5TmpFNU1XRTBOR0UyTVdRME5tTTFOamRtTURGalppSXNJblJoWnlJNklpSjk="
              target="_blank"
            >
              <img
                src="~/assets/img/license/anjoaun.png"
                alt="anjoaun license logo"
                width="80"
                class="inline-block transition-all"
              >
            </NuxtLink>
            <!-- <iframe
              src="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJa0V3TTJOdlJUWmFObmx1TVZGWU9FOHhhVWRtVUdjOVBTSXNJblpoYkhWbElqb2lWRVUwYURsQlVFbERNRkZXYlZoMWFIVkNVazF2ZHowOUlpd2liV0ZqSWpvaU9XVmlOREkxTnpRNU1UVTVOV1JrWWpZeE9Ea3pOV0ptTWpsbFptRTRPRFl5TmpBd09URmhNVEV5TmpFNU1XRTBOR0UyTVdRME5tTTFOamRtTURGalppSXNJblJoWnlJNklpSjk=  "
              width="122"
              height="50"
              class="border-none mx-auto lg:mx-0"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useLocationStore } from '@/store/location';

const props = defineProps({
  forceGamingContent: {
    type: Boolean,
    default: false,
  },
  isCompetition: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const locationStore = useLocationStore();
const {
  isGamingRestricted,
} = storeToRefs(locationStore);

const route = useRoute();

const showGamingContent = computed(() => props.forceGamingContent || !isGamingRestricted.value);
const footerYear = ref(new Date().getFullYear());
</script>
