import { defineStore } from 'pinia';
import { useWebsocketStore } from './websocket';

export const useUserStore = defineStore({
  id: 'user-store',
  state: () => {
    return {
      initiated: false,
      userData: null,
      userProfile: null,
      userNfts: null,
      userWalletNfts: null,
      levelForSalonRouge: 2,
    };
  },
  getters: {
    userLevel: (state) => {
      return state.userData !== null ? state.userData.level : 0;
    },
    isSalonRougePlayer: (state) => {
      return state.userData !== null && state.userData.level === state.levelForSalonRouge;
    },
    userFeatures: (state) => {
      const envVars = useRuntimeConfig().public;
      const forceAllowUserFeatures = envVars.FORCE_ALLOW_USER_FEATURES;

      if (forceAllowUserFeatures) {
        return {
          deposit: true,
          withdraw: true,
          games: true,
        };
      }

      const features = state.userData?.features || null;
      if (!features) { return null; }

      if (forceAllowUserFeatures) {
        for (const property in features) {
          features[property] = true;
        }
        if (!features.games) { features.games = true; }
      }
      return features;
    },
    userProfileUrl: (state) => {
      return state.userData?.displayName ? `/${state.userData.displayName.toLowerCase()}` : null;
    },
  },
  actions: {
    userInit() {
      if (this.initiated) { return; }

      this.initiated = true;
      const websocketStore = useWebsocketStore();
      websocketStore.$onAction(({ name, args, }) => {
        if (name === 'handleMessage') {
          if (args[0].type === 'User:LevelChanged') {
            useMessageHandler({ name, args, }, this.levelChanged, 'User:LevelChanged');
          }
        }
      });
    },
    /**
     * NB: Not currently used
     */
    async getUserNfts() {
      const nuxtApp = useNuxtApp();
      try {
        const userNfts = await nuxtApp.$api('/user/avatar/nft', { method: 'GET', });
        this.userNfts = userNfts;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getUserData() {
      const nuxtApp = useNuxtApp();
      try {
        const userData = await nuxtApp.$api('/user', { method: 'GET', });
        this.userData = userData;

        if (this.userData) {
          nuxtApp.$rollbar.configure({
            payload: {
              person: {
                id: userData.id,
                username: userData.displayName,
              },
            },
          });
        }
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async setUsername(name) {
      const nuxtApp = useNuxtApp();
      const body = {
        name,
      };
      await nuxtApp.$api('/user/display-name', { method: 'PUT', body, });
      await this.getUserData();

      if (this.userData) {
        nuxtApp.$rollbar.configure({
          payload: {
            person: {
              id: this.userData.id,
              username: this.userData.displayName,
            },
          },
        });
      }
    },
    async getUserProfile() {
      const nuxtApp = useNuxtApp();
      try {
        const userProfile = await nuxtApp.$api('/user/profile', { method: 'GET', });
        this.userProfile = userProfile;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async updateUserProfile(profile) {
      const nuxtApp = useNuxtApp();
      await nuxtApp.$api('/user/profile', { method: 'PUT', body: profile, }, 0);
      await this.getUserProfile();
    },
    async syncUserProfile() {
      const nuxtApp = useNuxtApp();
      await nuxtApp.$api('/user/profile/sync', { method: 'PUT', });
      await this.getUserProfile();
    },
    async getAnyUserWalletNfts(displayName, params) {
      const nuxtApp = useNuxtApp();
      try {
        const nfts = await nuxtApp.$api(`/nft/user/${displayName}`, {
          method: 'GET',
          params,
        });
        return nfts;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getAnyUserData(displayName) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api(`/user/public/${displayName}`, { method: 'GET', });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getUserNotificationSettings() {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/user/setting/notification', { method: 'GET', });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async updateUserNotificationSettings(channel, body) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api(`/user/setting/notification/${channel}`, {
          method: 'PUT',
          body,
        });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getActiveAliasChanges() {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/user/alias/change/active');
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async removeUserLoginIdentity(providerName, userId) {
      const nuxtApp = useNuxtApp();
      try {
        await nuxtApp.$api(`/user/login/identity/${providerName}/${userId}`, { method: 'DELETE', });
      } catch (err) {
        nuxtApp.$rollbar.error('removeUserLoginIdentity failed:', err);
      }
    },
    levelChanged(payload) {
      if (!this.userData) { return; }
      this.userData.level = payload.to;
    }
  },
});
